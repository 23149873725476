/* src/App.css */

/* Colours */
:root {
  /* Default */
  --primary-bg: #003135;
  --secondary-bg: #034951;
  --highlight-color: #AEDDE5;
  --project-color: #AEDDE5;
  --project-link-color: #034951;
  --accent-color: #964834;
  --reverse-accent-color: #034951;
  --text-color: #444;
  --subtext-color: #666;  
  --link-color: #007bff;
}

[data-theme='light'] {
  /* Light mode */
  --primary-bg: #f5f5f5;
  --secondary-bg: #ffffff;
  --highlight-color: #333333; 
  --project-color: #AEDDE5;
  --project-link-color: #034951;
  --accent-color: #964834;
  --reverse-accent-color: #034951;
  --text-color: #444;
  --subtext-color: #666;
  --link-color: #007bff; 
}

/* Body */
.App {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px; 
  margin: 0 auto; 
  background-color: var(--secondary-bg);
  padding-bottom: 20px;
}

body {
  background-color: var(--primary-bg);
}

@media (max-width: 768px) {
  .App {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* About */
.about {
  padding-top: 1rem;
  color: var(--highlight-color);
}

.about-container {
  padding: 20px;
}

.about-image {
  float: left;
  max-width: 320px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.about-image img {
  max-width: 100%;
  height: auto; 
  border-radius: 10px;
  float: left;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  margin-top: 0px;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .about {
    padding-top: 0rem;
  }

  .about-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center;
    gap: 0px; 
  }

  .about-image {
    margin-right: 0px;
  }

  .about-image img {
    width: 100%; 
    max-width: 250px; 
  }

  .about-text h2 {
    margin-top: 20px;
  }

  .about-text {
    padding-left: 0; 
  }

  .about-text p {
    font-size: 1rem; 
    line-height: 1.5;
  }
}

/* Projects */
.projects-header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--highlight-color);
}

/* Projects */
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.project-icon {
  color: var(--project-link-color);
}

.project-icon:hover {
  color: var(--accent-color);
}

.project-item {
  background-color: var(--project-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
}

.project-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.project-content {
  text-align: left;
  width: 100%;
}

.project-content h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--text-color);
}

.project-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--subtext-color);
}

.project-content button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.project-content button:hover {
  background-color: var(--reverse-accent-color);
}

.project-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .project-list {
    grid-template-columns: 1fr; 
    gap: 30px;
  }

  .project-item {
    flex-direction: column; 
    align-items: center;
  }

  .project-image {
    width: 100%;
  }

  .project-content {
    text-align: center;
  }
}

/* Contact */
.contact-details {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-item {
  font-size: 1.2rem; 
  color: var(--highlight-color); 
  margin: 10px 0; 
}

.contact-item a {
  color: var(--link-color); 
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline; 
}

/* Navbar */
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--secondary-bg);
  color: var(--highlight-color);
  position: relative;
}

/* Logo */
.nav-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-logo-image {
  width: auto;
  height: 2rem;
}

.nav-logo h1 {
  font-size: 2rem;
  margin: 0;
}

.nav-menu {
  display: flex;
  gap: 20px;
}

.nav-pages ul, .nav-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-pages ul li, .nav-links ul li {
  margin: 0 10px;
}

.nav-pages ul li a, .nav-links ul li a {
  color: var(--highlight-color);
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-pages ul li a:hover, .nav-links ul li a:hover {
  color: var(--accent-color);
}

.hamburger {
  display: none;
  cursor: pointer;
  color: var(--highlight-color);
}

.icon-text {
  display: none;
  margin-left: 10px;
}

.theme-toggle {
  margin-left: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-menu {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: var(--primary-bg);
    width: 100%;
    flex-direction: column;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease; 
  }

  .nav-menu.open {
    display: flex;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .nav-pages ul, .nav-links ul {
    flex-direction: column;
    align-items: center; 
  }

  .nav-pages ul li, .nav-links ul li {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hamburger {
    display: block;
  }

  .nav-links ul {
    flex-direction: column;
    padding: 0;
  }

  .nav-menu.open .icon-text {
    display: inline;
    font-size: 16px;
    color: var(--highlight-color);
    transition: color 0.3s; 
  }
  
  .nav-menu.open .nav-links ul li:hover .icon-text {
    color: var(--accent-color); 
  }

  .nav-links ul li {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center; 
  }

  .nav-links a {
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .theme-toggle {
    margin-left: 0px;
  }
}

/* Highlights */
.highlights {
  padding: 20px;
  text-align: center;
  color: var(--highlight-color);
}

.highlights-gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlights-gallery {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

/* Scrollbar styles for WebKit browsers (Chrome, Safari, Edge) */
.highlights-gallery::-webkit-scrollbar {
  height: 8px; 
}

.highlights-gallery::-webkit-scrollbar-track {
  background: var(--secondary-bg); 
}

.highlights-gallery::-webkit-scrollbar-thumb {
  background-color: var(--accent-color); 
  border-radius: 10px; 
  border: 2px solid var(--secondary-bg); 
}

.highlights-gallery::-webkit-scrollbar-thumb:hover {
  background-color: var(--highlight-color);
}

/* Firefox scrollbar styles */
.highlights-gallery {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--secondary-bg); 
}

.highlight-item {
  flex: 0 0 auto;
  width: 30%; 
  margin-right: 20px;
  scroll-snap-align: center;
  position: relative;
  overflow: hidden;
}

.highlight-item img {
  width: 100%;
  height: auto;
  object-fit: contain; 
}

/* Arrow Buttons */
.arrow {
  background-color: transparent;
  border: none;
  color: var(--highlight-color);
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.arrow:hover {
  color: var(--accent-color);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .highlight-item {
    width: 90vw;
    height: auto;
  }
}
